$(document).ready(function() {
  function fieldsetsWithErrors(fieldsets) {
    return fieldsets.has("p.inline-errors");
  }

  function addErrorClassToLink(fieldset) {
    let id = $(fieldset).attr("id");
    let overviewButton = $('.wizard-button[data-fieldset-id="#' + id + '"]');
    overviewButton.addClass("has-errors");
  }

  let fieldsets = $(".wizard-form").children("fieldset:not(.actions)");
  fieldsetsWithErrors(fieldsets).each((i, fieldset) => {
    addErrorClassToLink(fieldset);
  });
});
