(function($) {
	$.fn.countTo = function(options) {
		// merge the default plugin settings with the custom options
		options = $.extend({}, $.fn.countTo.defaults, options || {});

		return $(this).each(function() {
			var _this = this,
				loopCount = 0;
				
			var value = options.from || parseFloat($(this).html());
			if(isNaN(value)) value = 0;

			startTimer();
			
			// how many times to update the value, and how much to increment the value on each update
			var loops = Math.ceil(options.speed / options.refreshInterval),
				increment = (options.to - value) / loops;
			
			function startTimer() {
			  var oldTimer = $(_this).data("intervalTimer");
  			if(oldTimer) clearInterval(oldTimer);
  			
  			var newTimer = setInterval(updateTimer, options.refreshInterval);
  			$(_this).data("intervalTimer", newTimer);
  			return newTimer;
			}
			
			function stopTimer() {
			  var intervalTimer = $(_this).data("intervalTimer");
				clearInterval(intervalTimer);
				$(_this).data("intervalTimer", null);
			}

			function updateTimer() {
				value += increment;
				loopCount++;
				$(_this).html(value.toFixed(options.decimals));

				options.onUpdate.call(_this, value);

				if (loopCount >= loops) {
				  stopTimer();
					value = options.to;
					options.onComplete.call(_this, value);
				}
			}
		});
	};

	$.fn.countTo.defaults = {
		from: null,  // the number the element should start at
		to: 100,  // the number the element should end at
		speed: 1000,  // how long it should take to count between the target numbers
		refreshInterval: 100,  // how often the element should be updated
		decimals: 0,  // the number of decimal places to show
		onUpdate: function() {},  // callback method for every time the element is updated,
		onComplete: function() {},  // callback method for when the element finishes updating
	};
	})(jQuery);