$.widget("ui.livesearch", {
  // default options
  options: {
    interval: 500
  },

  _init: function() {
    this._addObserver();

    this._initQueryInput();

    this._initFilters();

    this._initKeyboardNav();

    this.element.data("busy", false);
  },

  _initQueryInput: function() {
    var queryInput = this.field();
    var self = this;

    this.element.click(function() {
      queryInput.focus();
    });

    // Autogrow input
    queryInput.autoGrowInput({
      comfortZone: 20,
      maxWidth: 600,
      minWidth: 200
    });

    // Remove last filter if query is empty an we
    // hit backspace.
    queryInput.bind("keydown", "backspace", function() {
      var selection = $(this).getSelection();
      if (selection.start == 0 && selection.length == 0) {
        self.removeFilter(
          self
            .filters()
            .last()
            .attr("id")
        );
      }
    });

    // Remove focus if we hit ESC
    queryInput.bind("keydown", "esc", function() {
      queryInput.blur();
    });

    queryInput.attr("autocomplete", "off");
    // Hide results list if we lose focus
    //queryInput.bind('blur', function() {
    //  self._hideResults();
    //});

    // Show result list if we gain focus
    // and have results.
    //queryInput.bind('focus', function() {
    //  if(queryInput.val() != "") self._showResults();
    //});
  },

  _initFilters: function() {
    this.element.find(".input .filter:first").addClass("first");
    this.element.find(".input .filter:last").addClass("last");

    var self = this;
    this.element.find(".input .filter a").click(function() {
      self.removeFilter(
        $(this)
          .parents(".filter")
          .attr("id")
      );
    });
  },

  _initKeyboardNav: function() {
    var self = this;
    var input = this.field();
    var results = this.results();

    results.find("li").on("mouseover mouseout", function(event) {
      if (event.type == "mouseover") {
        $(this).addClass("selected");
      } else {
        //results.find("li").removeClass("selected");
        $(this).removeClass("selected");
      }
    });

    // Cursor down to next or first element
    input.bind("keydown", "down", function() {
      var next = self
        .results()
        .children(".selected")
        .removeClass("selected")
        .next();
      if (next.length == 0)
        next = self
          .results()
          .children()
          .first();
      next.addClass("selected");
      return false;
    });

    // Cursor up to prev or last element
    input.bind("keydown", "up", function() {
      var prev = self
        .results()
        .children(".selected")
        .removeClass("selected")
        .prev();
      if (prev.length == 0)
        prev = self
          .results()
          .children()
          .last();
      prev.addClass("selected");
      return false;
    });

    // On enter activate current result item
    input.bind("keydown", "return", function() {
      var link = self
        .results()
        .children(".selected")
        .find("a");
      if (link.size() > 0) {
        var href = link.attr("href");
        if (href && href != "#") {
          location = href;
        } else {
          link.trigger("click");
        }
        return false;
      }
    });
  },

  _addObserver: function() {
    var self = this;
    this.field().delayedObserver(function() {
      self._onChange($(this).val());
    }, 0.5);
  },

  _onChange: function(value) {
    if (value.length == 0) this._hideResults();
    else this._doSearch();
  },

  _doSearch: function() {
    var form = this.form();
    $.get(
      form.attr("action"),
      form.serialize(),
      jQuery.proxy(this._onQueryDone, this),
      "script"
    );
  },

  isBusy: function() {
    return this.element.data("busy");
  },

  _onQueryDone: function(data) {},

  _showResults: function(callback) {
    this.results().slideDown(200, callback);
  },

  _hideResults: function(callback) {
    this.results().slideUp(200, callback);
  },

  handleResult: function(data) {
    var newResult = $(data);

    var newClass = newResult
      .attr("class")
      .split(" ")
      .sort()
      .join("");
    var oldClass = this.results()
      .attr("class")
      .split(" ")
      .sort()
      .join("");
    if (oldClass === newClass) {
      this._updateResults(newResult);
    } else {
      this._replaceResults(newResult);
    }

    //if(this.element.data("continue")) {
    //  this._doSearch();
    //  this.element.data("continue", false);
    //}

    //this.element.data("busy", false);
  },

  _replaceResults: function(new_res) {
    var self = this;
    this._hideResults(function() {
      //self.results().replaceWith(new_res);
      self
        .results()
        .html(new_res.children())
        .attr("class", new_res.attr("class"));
      self._showResults();
    });
  },

  _updateResults: function(new_res) {
    var self = this;

    // Make sure results are visible
    this._showResults();

    // Add all results if there is none
    var new_res_items = new_res.children();
    var old_res_items = this.results().children();

    // Case 1: No current results
    if (new_res_items.length > 0 && old_res_items.length == 0) {
      this.results().replaceWith(new_res);
      this._showResults();
    }

    // Case 2: No new results
    else if (new_res_items.length == 0 && old_res_items.length > 0) {
      this._hideResults();
    }

    // Case 3: Old and new results
    else {
      old_res_items.each(function() {
        var old_item = $(this);
        var new_item = new_res_items.filter("#" + this.id);

        if (new_item.length > 0) {
          var old_count = old_item.find(".counter");
          var new_count = new_item.find(".counter");
          old_count.countTo({ to: new_count.text(), speed: 300, interval: 10 });
        } else {
          old_item.slideUp(200, function() {
            old_item.remove();
          });
        }
      });

      new_res_items.each(function() {
        var new_item = $(this);
        var old_item = old_res_items.filter("#" + this.id);

        if (old_item.length == 0) {
          new_item
            .hide()
            .appendTo(self.results())
            .slideDown(200);
        }
      });
    }
  },

  hasFilter: function(filter, value) {
    var filterSelector =
      'input[name="search[filters][' + filter + ']"][value="' + value + '"]';
    return (
      this.filters()
        .has(filterSelector)
        .size() > 0
    );
  },

  hasntMoreThanOneFilter: function() {
    if (this.filters().size() <= 1) {
      return true;
    }

    return false;
  },

  addFilter: function(filter, value, name, skip_auto_filters) {
    if (this.hasFilter(filter, value)) {
      this._doSearch();
      return;
    }

    var filterId = filter + "_filter";
    var inputName = "search[filters][" + filter + "]";

    var li = $("<li />", {
      id: filterId,
      class: "filter last"
    });

    var input = $("<input />", {
      type: "hidden",
      name: inputName,
      value: value
    }).appendTo(li);

    var label = $("<label />", {
      for: inputName,
      text: name
    }).appendTo(li);

    var self = this;
    var button = $("<a />", {
      class: "remove",
      href: "#",
      text: "×",
      click: function() {
        self.removeFilter(filterId);
      }
    });

    button.appendTo(label);

    /* START DIRTY HACK */
    // this is needed to allow the view code to replace a AUTO-Filter.
    var existingFilter = this.element.find("#" + filterId);
    if (existingFilter.size() > 0) {
      existingFilter.remove();
    }
    /* END DIRTY HACK */
    this.element.find(".input .filter:last").removeClass("last");

    li.hide()
      .insertBefore(this.element.find(".input li:last"))
      .fadeIn(200);

    this.element.find(".input .filter:first").addClass("first");

    // Adding default filters
    if (this.hasntMoreThanOneFilter() && !skip_auto_filters) {
      if (
        this.hasFilter("class", "Spiel") ||
        this.hasFilter("class", "Anlass") ||
        this.hasFilter("class", "Gruppe") ||
        this.hasFilter("class", "Tableau") ||
        this.hasFilter("class", "Meisterschaft") ||
        this.hasFilter("class", "Turnier") ||
        this.hasFilter("class", "Kurs") ||
        this.hasFilter("class", "KursTeilnehmer")
      ) {
        this.addFilter(
          "saison",
          Globals.aktuelleSaison.id,
          Globals.aktuelleSaison.title
        );
      } else if (
        this.hasFilter("class", "Spieler") ||
        this.hasFilter("class", "Sportanlage") ||
        this.hasFilter("class", "Schiedsrichter") ||
        this.hasFilter("class", "Person") ||
        this.hasFilter("class", "Team") ||
        this.hasFilter("class", "Verein") ||
        this.hasFilter("class", "Spielsekretaer") ||
        this.hasFilter("class", "Funktionaer") ||
        this.hasFilter("class", "SpielerLizenz") ||
        this.hasFilter("class", "Nationalspieler")
      ) {
        this.addFilter("active", "1", "Aktive");
      }
    }

    if (this.query().length > 0) this._doSearch();
  },

  removeFilter: function(filterId) {
    var self = this;

    self.element
      .find(".input .filter:last")
      .prev()
      .addClass("last");

    this.element
      .find("#" + filterId)
      .next(".filter")
      .andSelf()
      .fadeOut(200, function() {
        $(this).remove();
        if (self.query().length > 0) self._doSearch();
      });
  },

  query: function() {
    return this.field().val();
  },

  results: function() {
    return this.element.find(".results");
  },

  filters: function() {
    var temp_filter = this.element.find(".input .filter");
    return this.element.find(".input .filter");
  },

  field: function() {
    return this.element.find("input[type='text']");
  },

  form: function() {
    return this.element.find("form");
  },

  destroy: function() {
    $.Widget.prototype.destroy.apply(this, arguments); // default destroy
  }
});
