jQuery.fn.intervalObserver = function(options) {
	var defaults = {
		interval: 500,
		onChange: function() {}
	};
	
	var settings = $.extend({}, defaults, options);
	var timer, value;

	this.focus(function() {
		var input = $(this);
		value = input.val();
		timer = setInterval(function() {
			checkValue(input);
		}, settings.interval);
	});
	
	this.blur(function() {
		var input = $(this);
		clearInterval(timer);
		checkValue(input);
		value = null;
	});
	
	return this;
	
	function checkValue(input) {
		if(input.val() != value) {
			settings.onChange.call(input, input.val());
			value = input.val();
		}
	}
};