$(document).ready(function(){
	// Make dashboard widgets sortable
	// $(".sortable-column").sortable({
	// 	connectWith: '.sortable-column',
	// 	revert: true
	// });

	// Remove widgets
	var removeButton = $('<a />').attr({
		'class': 'remove-button',
		'href': '#'
	}).text('Remove');

	//$('.portlet > .content-title').append(removeButton);

	// FIXME Remove does not function properly
	$('.remove-button').click(function(){
		$(getPorteltId(this)).remove();
	});

});

function getPorteltId(button){
	var portlet = $(button).parentsUntil('div.sortable-column');
	var id = $(portlet[1]).attr('id');
	return '#' + id;
}
