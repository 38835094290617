
# A model class that can be used to represent a simple record. This allows
# declaring attributes in your class definition and then handles a few messages
# like 
# 
#   #initWith other
#   #clone
#
class Model
  # Declares an attribute of the model. The attribute also becomes a constructor
  # argument (in order of declaration).
  #
  # Example: 
  #   @attr 'foo'
  #   @attr 'bar'
  #
  @attr: (name) ->
    @fields ||= []
    @fields.push name

  constructor: ->
    for name, idx in this.constructor.fields
      # console.log name, arguments[idx]
      this[name] = ko.observable(arguments[idx])

  # Initializes this object with the attributes of another object. The objects
  # must be of the same class. 
  #
  initWith: (other) ->
    for name, idx in this.constructor.fields
      value = other[name]
      this[name](value())

    return null

  # Initializes this object with the attributes of another object. The other 
  # object should be a plain (simple) javascript object.
  #
  initWithSimple: (other) ->
    for name, idx in this.constructor.fields
      value = other[name]
      this[name](value)

    return null

  # Returns all attributes of this object as a simple value. This is useful 
  # as a first step towards the network (serialisation)
  #
  attributes: ->
    res = {}
    for name in this.constructor.fields
      res[name] = this[name]()

    return res

  # Clones this object. 
  #
  clone: ->
    # Creates a list of arguments to our own constructor, including all the fields
    args = for name in this.constructor.fields
      this[name]()

    # Create a constructor that essentially applies our own constructor, but 
    # passing args above as arguments. 
    # see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Function/apply?redirectlocale=en-US&redirectslug=JavaScript%2FReference%2FGlobal_Objects%2FFunction%2Fapply
    oldConstructor = this.constructor
    argsConstructor = ->
      oldConstructor.apply(this, args)

    argsConstructor.prototype = oldConstructor.prototype

    return new argsConstructor()

ko.ta =
  model: Model
