/* Fix to have libs depending on an older jQuery version work (curCSS was renamed -> css) */
jQuery.curCSS = jQuery.css;

window.Application = (function(){

  return {

    addFlash: function(type, message) {
      $('#flash').append('<div class="'+ type +'">'+message+'</div>').find('div').flash();
    },

    removeErrors: function(container) {
      container.
        find('.inline-errors').remove().end().
        find('.error').removeClass('error');
    },

    addFieldError: function(field, message) {
      field.after('<p class="inline-errors">'+message+'</p>');
      field.parents('li.required').addClass('error');
    },

    buildAjaxSpinner: function(){
      return $('<div class="group-body"><div class="spinner"><img width="32px" height="32px" src="/images/ajax-loader.gif"></img></div></div>');
    },

    startProcess: function(element) {
      element.children().hide();
      element.append(Application.buildAjaxSpinner());
    },

    stopProcess: function(element) {
      $(element).find('.spinner').remove();
      $(element).children().show();
    },

    startWidgetProgress: function(widget) {
      if (!widget.is('.widget') && widget.parents('.widget').size() > 0) {widget = widget.parents('.widget');}
      widget.find('.widget-body').children().hide().
        end().append(Application.buildAjaxSpinner());
    },

    stopWidgetProgress: function(widget) {
      widget.find('.widget-body').find('.spinner').remove().
        end().children().show();
    }

  };

})();
