$.widget('ui.expandableFieldset', {
	_init: function(){
		var formId = this.element.attr('id');
		
		this.element.addClass('expandable-fieldset');
		this._addButtons();

		this.element.find('ol > li.expandable').each(function() {
			$(this).css({
				height: $(this).height()
			}).hide();
		});
	},
	
	_addButtons: function(){
		this.button = $('<a />').attr({
			'class': 'expandable-fieldset-button',
			'href': '#'
		}).text('⊞');
		
		this.button.appendTo(this.element.children('legend'));
		this.button.click($.proxy(this._onClick, this));
	},
	
	_onClick: function(event) {
		this.element.find('ol > li.expandable').slideToggle(200);
		return false;
	},
	
	//Not used yet, will be used for cookie support...
	_storeData: function(formId, targetFieldset, cookieData){
		var form = {form: formId, number: targetFieldset};
		
		if(cookieData != null){
			$.extend(cookieData, form);	
		}else{
			cookieData = form;
		}
		
		$.cookies.set('iExtendElementsCookie', cookieData, {expiration: 365});
	},
	
	_removeData: function(formId, targetFieldset){
		$.cookies.del('iExtendElementsCookie');
	},	
});