$(document).ready(function() {
  $("form").on("submit", function() {
    $("input[data-autocomplete]").each(function() {
      if ($(this).val() === "") {
        $($(this).attr("id_element")).val("");
      }
    });
  });

  $("#batch_strategy").on("change", function() {
    Application.startProcess($("#leistungszuweisung form"));

    var batchAttributes = {
      batch: {
        strategy: $(this).val()
      }
    };

    $.get("/leistungszuweisung/batches/new", batchAttributes, function(data) {
      $("#leistungszuweisung").replaceWith(data);
    });
  });

  $("#tableau").tableau();

  // Init expandable formtastic fields
  $(".formtastic fieldset.inputs:has(.expandable)").expandableFieldset();

  // Init jQuery UI datepicker
  $("input.ui-datepicker").datepicker();

  let timePickerOptions = {
    timeFormat: "hh:mm",
    showTime: false,
    timeOnlyTitle: "Zeit wählen",
    hourText: "Stunden",
    minuteText: "Minuten",
    secondText: "Sekunden",
    closeText: "Schliessen",
    currentText: "Jetzt"
  };

  $("input.ui-timepicker").timepicker(timePickerOptions);
  $("input.ui-timepicker-with-seconds").timepicker(
    _.merge(timePickerOptions, {
      showSecond: true,
      timeFormat: "hh:mm:ss"
    })
  );

  $("input.ui-datetimepicker").datetimepicker({ timeFormat: "hh:mm" });

  // Init action menu in header
  $("#actionmenu").actionmenu();

  // Person Accordion
  $(".accordion > li .toggle-button").click(function() {
    $(this)
      .parent()
      .siblings(".widget-body")
      .slideToggle(200, function() {
        $(this)
          .closest("li")
          .toggleClass("expanded");
      });
  });

  // Flash messages
  $("#flash div").flash();

  // Livesearch
  $("#header .search").livesearch();

  // Inline form hints
  $(".formtastic fieldset.inputs li")
    .children(".inline-hints")
    .hide()
    .end()
    .hover(
      function() {
        $(this)
          .children(".inline-hints")
          .toggle();
      },
      function() {
        $(this)
          .children(".inline-hints")
          .toggle();
      }
    );

  $(".chained-select").chainedSelect({
    childId: "#leistung_bezug_leistung_id"
  });

  $(".inline_destroy").bind("ajax:success", function() {
    $(this).slideUp(function() {
      $(this).remove();
    });
    var container = $(this).closest(".container_to_destroy ol");
    var target = container;
    if (container.size() === 0) {
      target = $(this).next("li");
      hidden_input = target.next();
      if (hidden_input.is("input")) {
        target = target.add(hidden_input);
      }
    }
    target.slideUp(function() {
      $(this).remove();
    });
  });

  $(".inline_destroy_spiel").bind("ajax:success", function () {
    $(this).slideUp(function () {
      $(this).remove();
    });
    var target = $(this).closest(".container_to_destroy");
    target.slideUp(function () {
      // this also removes the hidden input field containing the einsatz_id
      $(this).next('input').remove();
      $(this).remove();
    });
  });

  function updateBetrag() {
    var leistungsKategorieId = $(
      '*[name="leistung_bezug[kategorie_id]"]'
    ).val();
    var leistungId = $('*[name="leistung_bezug[leistung_id]"]').val();
    if (!leistungId || !leistungsKategorieId) {
      return;
    }

    $.getJSON(
      "/leistungskategorien/" +
        leistungsKategorieId +
        "/leistungen/" +
        leistungId +
        ".json",
      function(data) {
        var leistung = data.leistung;
        var reducedCheckBox = $("#leistung_bezug_virtual_reduced_input");
        var betrag = leistung.betrag;
        if (
          leistung.betrag_reduziert &&
          leistung.betrag_reduziert !== leistung.betrag
        ) {
          reducedCheckBox.slideDown();
          if (reducedCheckBox.find(":checkbox").is(":checked")) {
            betrag = leistung.betrag_reduziert;
          }
        } else {
          reducedCheckBox.slideUp();
        }
        var anzahl = parseInt($('*[name="leistung_bezug[anzahl]"]').val(), 10);
        $('*[name="leistung_bezug[betrag]"]').val(betrag * anzahl + "");
      }
    );
  }

  $('*[name="leistung_bezug[kategorie_id]"]')
    .add('*[name="leistung_bezug[anzahl]"]')
    .add('*[name="leistung_bezug[leistung_id]"]')
    .add("#leistung_bezug_virtual_reduced_input")
    .change(updateBetrag);
});
