$.widget('ui.keyboardNavUL', {
	
	// default options
	options: {
		onSelect: function(currentLi) {
			$(currentLi).find('a').click();
		}
	},
  
	_init: function(){
		this.element.bind('keydown', $.proxy(this._onKeyDown, this));
		this._initMouseOverAndOut();
	},
	
	_initMouseOverAndOut: function(){
		// TODO Replace static references
		$('.results li').on('mouseover', $.proxy(this._onMouseOver, this));
		$('.results li').on('mouseout', $.proxy(this._onMouseOut, this));
	},
	
	_onMouseOver: function(event){
		this._removeCurrentHighlighted();
		$(event.currentTarget).addClass('selected');
	},
	
	_onMouseOut: function(){
		this._removeCurrentHighlighted();
	},
	
	_onKeyDown: function(event, keyEvent) {
		var ulChildren = this.element.children();
		var currentHighlightedElement = $('.selected').get(0);
		
		if(keyEvent.which === 38 || keyEvent.which === 40 || keyEvent.which === 13){
			this._removeCurrentHighlighted();

			this._newHighlightedElement(keyEvent, currentHighlightedElement, ulChildren);
		}
	},
	
	_removeCurrentHighlighted: function(){
		var currentHighlightedElement = $('.selected').get(0);
		$(currentHighlightedElement).removeClass('selected');
	},
	
	_newHighlightedElement: function(keyEvent, currentHighlightedElement, ulChildren){
		var currentHightlightedElementId;

		if(currentHighlightedElement == null) {
			currentHightlightedElementId = $(ulChildren[0]).attr('id');
			$('#' + currentHightlightedElementId).addClass('selected');
		}else{
			currentHightlightedElementId = '#' + $(currentHighlightedElement).attr('id');
		}
		
		var handled = false;
		switch(keyEvent.which) {
			//upArrow
			case 38:
				this._doNewHighlighting(currentHighlightedElement, $(currentHightlightedElementId).prev());
				handled = true;
				break;
			//downArrow
			case 40: 
				this._doNewHighlighting(currentHighlightedElement, $(currentHightlightedElementId).next());
				handled = true;
				break;
			//enter
			case 13:
				this.options.onSelect(currentHighlightedElement);
				handled = true;
				break;
		}
		
		if(handled) keyEvent.preventDefault();
	},
	
	_doNewHighlighting: function(currentHighlightedElement, newHighlightedElement){
		$(currentHighlightedElement).removeClass('selected');
		$(newHighlightedElement).addClass('selected');
	},
});