// Inspired by habariproject.org

$.widget("ui.actionmenu", {

    _init: function() {
      this._initButton();
      this._initNavigation();
      this._initItems();
    },

    _initButton: function() {
      var button = this.button();
      var hotkey = $("<span />").attr('class', 'hotkey').text(button.attr("data-hotkey"));

      button
        .append(hotkey)
        .click(function(event) {
          event.preventDefault();
          return false;
        });

      this.element.hover($.proxy(this.activate, this), $.proxy(this.deactivate, this));
    },

    _initNavigation: function() {
      var self = this;

      // Global HotKeys
      $(document)
        .bind('keydown', this.button().attr("data-hotkey"), $.proxy(this.toggle, this))
        .bind('keydown', 'esc', $.proxy(this.deactivate, this));

      // Navigation
      $(document)
        .bind('keydown', 'down',  this._whenActive(this.down))
        .bind('keydown', 'up',    this._whenActive(this.up))
        .bind('keydown', 'left',  this._whenActive(this.left))
        .bind('keydown', 'right', this._whenActive(this.right));

      // If menu is open and mouse is clicked outside menu, close menu.
      $('html').click(function() {
        if(self.isActive()) self.deactivate();
      });
    },

    _whenActive: function(fn) {
      var self = this;
      return function() { return self.isActive() ? $.proxy(fn, self).call() : true; };
    },

    setCaret: function(item) {
      this.items().filter(".caret").removeClass("caret");
      item.parentsUntil(this.element).filter("li").addClass("caret");
      item.addClass("caret");
    },

    down: function() {
      var item = this.currentItem();
      var menu = this.currentMenu();

      if (item.length == 0) { this.setCaret(menu.children("li:first")); }
      else { this.setCaret(item.next()); }

      return false;
    },

    up: function() {
      var item = this.currentItem();
      var menu = this.currentMenu();

      if (item.length == 0) { this.setCaret(menu.children("li:last")); }
      else { this.setCaret(item.prev()); }

      return false;
    },

    left: function() {
      this.currentItem().removeClass("caret");
      return false;
    },

    right: function() {
      this.setCaret(this.currentItem().children("ul").children("li:first"));
      return false;
    },

    _initItems: function() {
      var self = this;

      // Set caret when hovering
      this.items().mouseover(function() {
        self.setCaret($(this));
      });

      // Add class 'submenu' if item has a nested menu
      this.items().filter(":has(ul)").addClass("submenu");

      // Add hotkeys
      this.items().each(function() {
        var item = $(this);

        var link = $(this).children("a");

        var hotkey = $(this).attr("data-hotkey");

        if(hotkey) {

          // Add hotkey span
          $("<span />")
            .appendTo(link)
            .addClass("hotkey")
            .text(hotkey);

          // Bind events
          $(document).bind('keydown', hotkey, self._whenActive(function() {
            if(item.hasClass("submenu") || item.parent().parent().hasClass("caret") || item.parent().parent()[0] == self.element[0]) {
              self.setCaret(item);
              self.goto(item);
            }
          }));
        }
      });

        // Add click handler
        this.items().click(function () {
            // pass event to rails.js
            if ($(this).children('a').data('method')) {
                return true
            }
            self.goto($(this));
            return false;
        });

    },

    goto: function(item) {
      var self = this;

      var link = item.children("a");
      var href = link.attr("href");

      if(href && href != "#") {
         var blinkSpeed = 100;

        // Blink item then goto link
        link
          .fadeOut(blinkSpeed).fadeIn(blinkSpeed)
          .fadeOut(blinkSpeed).fadeIn(blinkSpeed, function() {
              self.deactivate();
              location = href;
          });

      }
    },

    // BUTTON ======================

    button: function() {
      return this.element.children("a");
    },

    //  PRIMARY MENU ===============

    rootMenu: function() {
      return this.element.children("ul");
    },

    isActive: function() {
      return this.rootMenu().css('display') == 'block';
    },

    toggle: function() {
      this.isActive() ? this.deactivate() : this.activate();
    },

    activate: function() {
      this.element.addClass("active");
      this.rootMenu().show();
    },

    deactivate: function() {
      this.element.removeClass("active");
      this.rootMenu().hide();
      this.rootMenu().find(".caret").removeClass("caret");
    },

    currentMenu: function() {
      var item = this.currentItem();
      return item[0] ? item.closest("ul") : this.rootMenu();
    },

    items: function() {
      return this.rootMenu().find("li");
    },

    currentItem: function() {
      return this.rootMenu().find(".caret:last");
    }
});