$(function(){
  var funInitDateList = function(){
    var datelistWidget = $('#datelist');
    var datelist = datelistWidget.find('table.dates tbody');
    var funIsDateSelected = function(date) {
      var selectedDates = datelist.find('td').not('.placeholder');
      var selectedDatesText = $.map(selectedDates, function(e){ return $(e).text(); });
      return selectedDatesText.indexOf(date) != -1;
    };
    var funSortDates = function() {
      datelist.find('td').sortElements(function(a, b){
        return $(a).text().split('.').reverse().join('.') > $(b).text().split('.').reverse().join('.');
      });
    };
    var funAddDate = function(date){
      var selectedDates = datelist.find('td').not('.placeholder');
      $(datelist.find('td.placeholder').get(0)).text(date).removeClass('placeholder');
    };

    var funRemoveDate = function(data) {
      var selectedDates = datelist.find('td').not('.placeholder');
      var selectedDatesText = $.map(selectedDates, function(e){ return $(e).text(); });
      var dateItem = $(selectedDates.get(selectedDatesText.indexOf(data)));
      dateItem.html("&nbsp;").addClass('placeholder');
    };
    var funUpdateForm = function() {
      $('.anlass_datum').val('');
      var selectedDates = datelist.find('td').not('.placeholder');
      selectedDates.each(function(i, e){
        var rundeId = i + 1;
        $('#runde_'+rundeId).find('.anlass_datum').val($(e).text());
      });
    };


    $('#day-chooser').datepicker({
      onSelect: function(date){
        if (funIsDateSelected(date)) {
          funRemoveDate(date);
        } else {
          funAddDate(date);
        }
        funSortDates();
        funUpdateForm();
      },
      beforeShowDay: function (date){
        var textDate = $.datepicker.formatDate($(this).datepicker('option', 'dateFormat'), date);
        if (funIsDateSelected(textDate)) {
          // Enable date so it can be deselected. Set style to be highlighted
          return [true,"ui-state-highlight"];
        }
        // Dates not in the array are left enabled, but with no extra style
        return [true, ""];
      }
    });
  };

  setTimeout(funInitDateList, 10);

  $('.switch').click(function(e){
    var row = $(this).parents('tr');

    // switch ids
    var heimId = row.find('.team_id.heim');
    var gastId = row.find('.team_id.gast');
    var heimIdContent = heimId.val();
    heimId.val(gastId.val());
    gastId.val(heimIdContent);

    // switch links
    var heimCell = row.find('.team.heim');
    var gastCell = row.find('.team.gast');
    var heimContent = heimCell.html();
    heimCell.html(gastCell.html());
    gastCell.html(heimContent);

    var heimTore = row.find('.tore.heim input');
    var gastTore = row.find('.tore.gast input');
    var heimToreContent = heimTore.val();
    heimTore.val(gastTore.val());
    gastTore.val(heimToreContent);

    e.preventDefault();
  });

  $('#display_filters .filter').click(function(){
    $('#display_filters .filter').removeClass('active');
    $(this).addClass('active');
  });

  $('#filter_spiele').click(function(e){
    $('.anlass .fields').hide();
    $('.anlass .spiele').show();
    e.preventDefault();
  });

  $('#filter_all').click(function(e){
    $('.anlass .fields').show();
    $('.anlass .spiele').show();
    e.preventDefault();
  });

  $('#filter_anlaesse').click(function(e){
    $('.anlass .fields').show();
    $('.anlass .spiele').hide();
    e.preventDefault();
  });

});
