var chainedSelectHiddenChildren = new Array();
var chainedSelectChildId = "";

$.widget('ui.chainedSelect', {
	options: {
		childId: null
	},

	_init: function(){
		$('.' + $(this.element[0]).attr('class')).bind('change', $.proxy(this._showChildren, this));

		chainedSelectChildId = this.options.childId;

		chainedSelectHiddenChildren = $(chainedSelectChildId).children().clone();

    var firstElementGroup = $(this.element[0]).children(':first-child').attr('value');

    $(chainedSelectChildId).empty();

    chainedSelectHiddenChildren.each(function(index, element){
      if($(element).attr('label') == firstElementGroup){
        $(chainedSelectChildId).append(element);
      }
    });

    // PATCH: Yves Senn, trigger change event when a value is selected
    if ($(this.element[0]).val()) {
      this._showChildren();
    }
	},

	_showChildren: function(event){
		var this_parent = this.element[0];
		chainedSelectHiddenChildren.each(function(index, element){
			if($(element).attr('label') == $(this_parent).attr('value')){
				$(chainedSelectChildId).empty();
				$(chainedSelectChildId).append(element);
			}
		});
	}
});
