$.widget("ui.tableau", {

  options: {
    spielChanged: function(e, spiel) {
      spiel.removeClass('error');
      spiel.removeClass('success');
      Application.startProcess(spiel);
    },
    spielUpdated: function(e, data) {
      $.getJSON('/spiele/' + data.spiel.attr('data-spiel-id') + '.json',
                function(spielResponse){
                  if (data.success) {
                    data.spiel.addClass('success');
                    data.spiel.find('.heim input').val(spielResponse.spiel.heim_team_name);
                    data.spiel.find('.gast input').val(spielResponse.spiel.gast_team_name);
                  } else {
                    data.spiel.addClass('error');
                  }
                  Application.stopProcess(data.spiel);
                  data.ui.updateUnassignedTeams();
                });
    }
  },

  _init: function(){
    this._initDraggables();
    this._initDroppables();
    this._initAutocompleters();
    this._initFreilosTogglers();
    this._initDeleteTeamLinks();
  },

  _initDeleteTeamLinks: function(){
    var self = this;
    this.element.find('.delete_team').click(function(){
      $(this).parents('.team').attr('data-team-id', '').
        find('input').val('');
      self.updateSpiel($(this).parents('.spiel'));
    });
  },

  _initFreilosTogglers: function(){
    var self = this;
    this.element.find('.freilos_toggler').change(function(){
      self.updateSpiel($(this).parents('.spiel'));
    });
  },

  _initAutocompleters: function(){
    var self = this;
    this.element.find('.team_completer').bind('ac:completed', function(){
      self.updateSpiel($(this).parents('.spiel'));
    });
  },

  _initDraggables: function(){
    this.element.find('.team').draggable({
      handle: '.handle',
      revert: 'invalid'
    });
  },

  _initDroppables: function(){
    var self = this;
    this.element.find('.team').droppable({
      accept: '.team',
      tolerance: 'intersect',
      drop: function(event, ui){
        var droppedTeam = ui.draggable;
        droppedTeam.removeClass('ui-draggable-dragging').
               css({position:'relative', left:0, top:0});
        self.switchTeams($(this), droppedTeam);
      }
    });
  },

  switchTeams: function(teamOne, teamTwo){
    var origin = teamTwo.parents('.heim, .gast');
    teamTwo.detach();
    teamOne.parents('.heim, .gast').append(teamTwo);
    teamOne.detach();
    origin.append(teamOne);
    this.updateSpiel(teamOne);
    this.updateSpiel(teamTwo);
  },

  updateSpiel: function(spiel) {
    if (!spiel.is('.spiel')) { spiel = spiel.parents('.spiel'); }
    var self = this;
    this._trigger('spielChanged', null, spiel);

    var spielId = spiel.attr('data-spiel-id');
    var spielAttributes = this.spielAttributesFor(spiel);
    $.ajax({
      url: '/spiele/' + spielId + '.json',
      type: "POST",
      data: {
        '_method': 'put',
        'spiel': spielAttributes
      },
      dataType: 'text',
      success: function(data){
        self._trigger('spielUpdated', null, {spiel: spiel,
                                             success:true,
                                             ui: self});
      },
      error: function(){
        self._trigger('spielUpdated', null, {spiel: spiel,
                                             success:false,
                                             ui: self});
      }
    });
  },

  updateUnassignedTeams: function(){
    var unassignedTeamsWidget = $('.widget.unassigned_teilnahmen');
    Application.startWidgetProgress(unassignedTeamsWidget);

    var tableauId = this.element.attr('data-tableau-id');
    $.get('/tableaux/'+tableauId+'/unassigned_teams.html', function(data){
      unassignedTeamsWidget.replaceWith(data);
    });
  },

  spielAttributesFor: function(spiel) {
    return {
      heim_team_id: spiel.find('.heim .team').attr('data-team-id') || '',
      gast_team_id: spiel.find('.gast .team').attr('data-team-id') || '',
      freilos: spiel.find('.freilos_toggler input').is(':checked')
    };
  }

});
